<template>
  <!-- Renderiza un item de menu del estilo AdminLTE -->
  <li class="nav-item" :class="clasesNavItem">
    <app-link :to="ruta" class="nav-link" :class="{ active: activo }">
      <i class="nav-icon" :class="clasesIcono"></i>
      <p><slot /></p>
    </app-link>
    <ul v-if="tieneSubmenu" class="nav nav-treeview">
      <slot name="submenu"></slot>
    </ul>
  </li>
</template>

<script>
export default {
  props: [
      "ruta", 
      "clasesIcono", 
      "nombre", 
      "clasesNavItem",
      "activo"
    ],
  computed: {
    tieneSubmenu() {
      return true;//!!this.$slots.submenu;
    }
  }
};
</script>
