<template>
    <!-- Se encarga de renderizar un menu con submenus -->
    <nav-item v-for="menuitem in menudos" :key="menuitem.nombre" :ruta="menuitem.ruta"
        :clasesNavItem="menuitem.clasesNavItem" :clasesIcono="menuitem.clasesIcono" >
        <span>{{ menuitem.nombre}}</span>
        <i v-if="menuitem.submenu" class="right fas fa-angle-left"></i>
        <span v-if="menuitem.badge" style="position: relative; left: 130px;" class="badge" :class="['badge-' + menuitem.badge.tipo]">
            {{ chats }}
        </span>
        <!-- Parte del submenu, en caso de haberlo -->
       
    </nav-item>
</template>
<script>
import { PwgsApi } from '../services/PwgsApi';
export default {
    data() {
        return {
            chats:0,
            menudos: [
                {
                    nombre: 'Escritorio / Servicios',
                    ruta: '/escritorio',
                    clasesIcono: 'fas fa-home'
                },
                {
                    nombre: 'Configuración',
                    ruta: '/configuracion',
                    clasesIcono: 'fas fa-cogs',

                },
                {
                    nombre: 'Planning',
                    ruta: '/planning',
                    clasesIcono: 'fas fa-calendar-alt'

                },
                {
                    nombre: 'Informes',
                    ruta: '/Informes',
                    clasesIcono: 'fas fa-desktop',
                },
                {
                    nombre: 'Chat',
                    ruta: '/chat',
                    clasesIcono: 'fab fa-weixin',
                    badge: {"posicion":'pull-right-container','tipo':'label pull-right bg-purple'}
                },
            ]
        }
    },
    methods:{
        async comprobarChats(){
            const api = new PwgsApi();
            var resp = this.chats = await api.get('chat-pwgs');
            this.chats = resp.n_total_registros;
        },
        async comprobarPlanning(){
            const api = new PwgsApi();
            const resp = await api.get('parametros-pwgs/planning_geografico');
            console.log('respui', resp);
            if(resp.planning_geografico == 1){
                var planningGeografico ={
                    nombre: 'Planning Geográfico',
                    ruta: '/planningGeografico',
                    clasesIcono: 'fas fa-map-marked-alt'
                }
                this.insertarDespuesDePlanning(planningGeografico);
            }

        },
        insertarDespuesDePlanning(nuevoItem) {
            const planningIndex = this.menudos.findIndex(item => item.nombre === 'Planning');
            if (planningIndex !== -1) {
                this.menudos.splice(planningIndex + 1, 0, nuevoItem);
            } else {
                // If 'Planning' is not found, add to the end of the array
                this.menudos.push(nuevoItem);
            }
        }
    },
    mounted(){
        this.comprobarChats();
        this.comprobarPlanning();
        setInterval(()=>this.comprobarChats(), 60000);
    }
    
}
</script>